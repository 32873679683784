import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Creating an Analysis`}</h1>
    <ol>
      <li parentName="ol">{`Export data`}</li>
      <li parentName="ol">{`Move data into folder with a fresh copy of the master Jupyter notebook`}</li>
      <li parentName="ol">{`Run notebooks with your data`}</li>
      <li parentName="ol">{``}<a parentName="li" {...{
          "href": "/Analysis",
          "title": "🔬 Analysis"
        }}>{`🔬 Analysis`}</a>{``}</li>
      <li parentName="ol">{`Collecting findings`}</li>
    </ol>
    <p>{`See: `}<a parentName="p" {...{
        "href": "/services",
        "title": "services"
      }}>{`services`}</a>{``}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      